<template>
  <div class="shop">
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad">
        <div class="center"
          v-for="item in list"
          :key="item.index">
          <!-- @click="xiangqing(item.ID) <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="Content"
            style="padding: 8px 10px;line-height: 30px;">
            <div style="display: inline-block;">
              <span style="font-size: 18px; color: #333">
                <van-icon name="manager" />{{ item.Name }}
                <van-icon name="phone" />{{ item.Mobile }}</span>
              <br />

              <span class="centertime"
                style="font-size: 18px;color: #333">
                报备时间：{{ item.AddTime }}
              </span>
              <br />
              <!-- <span class="centertime"
                style="font-size: 18px;color: #333">
                核酸检测次数：{{ item.NACheckCount }}
              </span> -->
              <!-- <span class="centertime"
                style="font-size: 18px;color: #333">
                住址：{{ item.Address }} -->
              <span class="centertime"
                style="font-size: 18px;color: #333">
                接种情况：{{ item.StatusDesc }}
              </span><br />
              <span class="centertime"
                style="font-size: 18px;color: #333">
                接种时间：{{ item.VaccinateTime | capitalizeTime('电话号码')}}
              </span>
            </div>
            <div style="display: inline-block;width: 95%;text-align: right;"
              class="ContentB">
              <span class="centertime"
                style="color: #333">
                <van-button type="info"
                  round
                  style="width: 80px;font-size:13px;"
                  @click="yimiaoUpdata(item)"
                  size="small">更新</van-button>
              </span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
      <!-- <div>
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px"> 
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 100%"
            @click="addShop()"
            color="#617bfa">新增商铺</van-button>
        </div>
      </div> -->
      <van-dialog v-model="showTc"
        title="提示"
        @confirm='confirm'
        show-cancel-button>
        <van-cell-group>
          <van-field v-model="datafrom.StatusDesc"
            required
            @click="Status = true"
            readonly="readonly"
            label="接种疫苗情况"
            placeholder="请选择接种疫苗情况" />
          <van-popup v-model="Status"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="yimiaolist"
              value-key="Name"
              @cancel="Status = false"
              @confirm="onStatus">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-field v-model="datafrom.VaccinateTime"
          name="VaccinateTime"
          label="接种时间"
          @click="jiezhongTime = true"
          readonly="readonly"
          placeholder="请选择接种时间" />
        <van-popup v-model="jiezhongTime"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="jiezhognDate"
            show-toolbar
            type="date"
            title="请选择接种时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="jiezhongTime = false"
            @confirm="onjiezhongFend">
          </van-datetime-picker>
        </van-popup>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import { WxGetCheckPage, WxUpdateVaccinateStatus } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      showTc: false,
      Status: false,
      jiezhongTime: false,
      minDate: new Date(2015, 0, 1),
      maxDate: new Date(2030, 10, 1),
      dataform: {},
      jiezhognDate: "",
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      datafrom: {

      },
    };
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {

    // 疫苗选择器
    onStatus (val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusDesc = val.Name;
      this.datafrom.VaccinateTime = '';
      this.Status = false;
    },
    onjiezhongFend (val) {
      //接种时间 确认
      this.datafrom.VaccinateTime = this.dateformat(val);
      this.jiezhongTime = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 员工信息
    entYuanGong (row) {
      console.log(row);
      this.$router.push({
        // name: "ygList",
        path: "/shop/myShop/ygList/" + row.ShopId + "/" + 2,
        // query: { seId: row.EId, ekind: 1 },
      });

    },
    //新增
    addShop () {
      this.$router.push({
        name: "addShop",
        query: { bfaId: this.itemmark, add: 1 },
      });
    },
    //重新绑定
    shopBangDing (shopId) {
      this.$router.push({
        name: "addShop",
        query: { shopId: shopId, add: 3 },
      });
    },
    //编辑
    // yimiaoUpdata (row) {
    //   console.log(row);
    //   var itemmark = JSON.stringify(row);
    //   this.$router.push({
    //     name: 'yiMiaoUpdata', params: { itemmark }
    //   })
    //   // this.$router.push({
    //   //   path: "/register/fangyi/myYiMiao/yimiaoList/yiMiaoUpdata/" + itemmark,
    //   // });
    // },
    //更细疫苗接种信息
    yimiaoUpdata (row) {
      console.log(row);
      this.showTc = true
      this.datafrom.ID = row.ID;
      this.datafrom.Status = row.Status;
      this.datafrom.StatusDesc = row.StatusDesc;
      this.datafrom.VaccinateTime = row.VaccinateTime.substring(0, 10);
    },

    confirm: function () {
      if (!this.datafrom.Status) {
        Toast.fail("请选择接种情况");
        return false
      }
      if (!this.datafrom.VaccinateTime) {
        Toast.fail("请选择接种日期");
        return false
      } else {
        WxUpdateVaccinateStatus({
          ID: this.datafrom.ID,
          Status: this.datafrom.Status,
          VaccinateTime: this.datafrom.VaccinateTime,
        })
          .then((res) => {
            console.log(res.data.code);
            if (res.data.code == 0) {
              Toast.success("操作成功");
              this.getList();
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => { });
      }

      // this.showTc = true
    },

    //详情
    xiangqing (ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/mkfqXiangQing/" + ID,
      });
    },
    // 获取分页列表
    getList () {
      WxGetCheckPage({ openID: getOpenId() })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },

    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>